<!--
 * @Author: 羡国柱
 * @Date: 2023-07-18 14:58:13
 * @LastEditors: 羡国柱
 * @LastEditTime: 2023-07-18 15:07:38
 * @FilePath: \src\components\scrollWrapper\SEduArchives\Sub\ProgrammeDetail.vue
 * @Description: 智慧教学 > 综评档案 > 方案配置 -- 详情
-->
<template>
	<div class="programme-detail-pages">
		<el-form  label-position="left" :label-width="formLabelWidth" :model="dengmiDataDetail" 
			ref="dengmiDataDetail"  size="small" :disabled="true" v-loading="dengmiDataDetail.loading">
			<el-form-item label="方案名称" prop="title" >
				<el-input v-model="dengmiDataDetail.title"></el-input>
			</el-form-item>
			<el-form-item label="年级名称" prop="grade">
				<div v-for="item in dengmiDataDetail.gradeDataList" :key="item.id">
					<el-input v-model="item.name" ></el-input>
				</div>
			</el-form-item>
			<el-form-item label="关联考试" prop="exam" class="exam-wrap">
				<el-input v-model="dengmiDataDetail.exam" name="exam" placeholder="关联的考试" readonly></el-input>
				<el-collapse-transition>
					<div class="exam-wrap-list" v-show="dengmiDataDetail.examListBox">
						<div class="exam-wrap-list-btn">
							<div class="confirm-btn" @click="examSeleConfirm">
								<img src="../../../../assets/images/qianxi_emax_confirm.png" alt="">
							</div>
							<div class="cancel-btn"  @click="examSeleCancel">
								<img src="../../../../assets/images/qianxi_emax_cancel.png" alt="">
							</div>
						</div>
						<div class="exam-wrap-list-box">
							<el-checkbox-group v-model="dengmiDataDetail.examList" @change="getEditExamList">
								<el-checkbox v-for="item in dengmiDataDetail.examOptions" :key="item.id" :label="item.id">{{ item.examName }}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</el-collapse-transition>
				<el-collapse-transition>
					<div class="exam-wraper-list" v-if="dengmiDataDetail.examListBoes">
						<div v-for="item in dengmiDataDetail.examDataList" :key="item.id" 
							class="exam-wraper-list-cell" @mouseenter="isEditOperateShow(item)" @mouseleave="isEditOperateHide">
							<div class="exams-title">
								<span :title="item.examName">{{ item.examName }}</span>
								<i class="el-icon-close" v-show="isOperate === item.id" @click="deleteEditExam(item)"></i>
							</div>
							<div class="exams-radio" v-show="isOperate === item.id">
								<el-radio v-model="item.radio" :label="item.id" @input="getEditExamRadio(item)">有附加分</el-radio>
							</div>
						</div>
					</div>
				</el-collapse-transition>
			</el-form-item>
			<div style="height: 150px;" v-show="dengmiDataDetail.hidenHeight"></div>
			<div>
				<div v-for="(item, index) in dengmiDataDetail.appendPList" :key="item.id">
					<div style="color: 16px; font-size: 16px; font-weight: 600; padding-bottom: 24px;" v-if="item.examName">
						{{'【' + item.examName  + '】'}}关联行为设置
					</div>
					<el-form-item label="起止日期">
						<el-date-picker v-model="item.detaTime" clearable type="daterange"
							range-separator="至" value-format="yyyy-MM-dd" start-placeholder="起始日期" end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
					
					<el-form-item >
						<el-table
							style="width: 100%"
							border stripe size="mini"
							:data="item.tableData"
							:header-cell-style="{ background:'#F1F1F1',color:'#2B2F33' }">
							<el-table-column label="序号" type="index" align="center" width="55px"></el-table-column>
							<el-table-column align="left" label="行为" show-overflow-tooltip >
								<template slot-scope="scope">
									<span>{{ scope.row.ruleName }}</span>
								</template>
							</el-table-column>
							<el-table-column align="center" label="运算" width="55px">
								<template slot-scope="scope">
									<span v-if="scope.row.ruleType === '1'">-</span>
									<span v-if="scope.row.ruleType === '2'">+</span>
								</template>
							</el-table-column>
							<el-table-column align="center" label="分值" width="100px">
								<template slot-scope="scope">
									<span>{{ scope.row.score }}</span>
								</template>
							</el-table-column>
							<div slot="empty">
								<p :style="{'marginTop': '23px'}">暂无数据</p>
							</div>
						</el-table>
					</el-form-item>
				</div>
			</div>
		</el-form>
		<dialog-wrapper :dialog-obj="dialogTargetObj" @handleClose="handleTargetClose">
			<div class="target-list">
				<div style="display: flex;">
					<div class="target-list-query">
						<el-input v-model="targetName" clearable placeholder="行为名称"></el-input>
					</div>
					<div class="target-list-number">
						<span>已选行为</span>
						<span>{{ dialogTargetObj.targetSeleteList.length }}</span>
					</div>
				</div>
				
				<div class="target-list-boes">
					<div class="target-list-boes-row target-list-boes-lt">
						<el-tree ref="tree" show-checkbox node-key="id" 
							:data="dialogTargetObj.targetDataList" 
							:props="dialogTargetObj.defaultProps"
							:default-expanded-keys="dialogTargetObj.expandedKeys"
							:default-checked-keys="dialogTargetObj.checkedkeys"
							:filter-node-method="filterNode"
							@node-click="handleNodeClick" 
							@check-change="handleCheckChange">
						</el-tree>
					</div>
					<div class="target-list-boes-row target-list-boes-rt">
						<div v-for="(item, index) in dialogTargetObj.targetSeleteList" :key="index" class="target-list-boes-rt-row">
							<div class="target-selete-cell">
								<div class="target-selete-cell-delete" @click="targetEditDelete(item)">
									<i class="el-icon-error"></i>
								</div>
								<div class="target-selete-cell-name">{{ item.ruleName }}</div>
							</div>
						</div>
					</div>
				</div>
				<div style="text-align: right; margin-top: 20px;">
					<el-button @click="targetSeleEditCancel">取消</el-button>
					<el-button type="primary" @click="targetSeleEditConfirm">保存</el-button>
				</div>
			</div>
		</dialog-wrapper>
	</div>
</template>

<script>
	import {
		// 业务组件
		FilterData,
		TableData,
		DialogWrapper,
		Pagination,
		// 功能组件
		Error,
		Loading,
		// 工具函数
		throttle,
	} from "common-local";
	import {
		mapState
	} from 'vuex';
	import {
		hasPermission
	} from "@/libs/utils";
	import ExpandFilter from "../../Sub/ExpandFilter";

	export default {
		name: 'ProgrammeDetail',
		components: {
			FilterData,
			TableData,
			Pagination,
			DialogWrapper,
			// 功能组件
			Error,
			Loading,
			ExpandFilter
		},
		props: {
			dialogDetailObj: {
				type: Object
			}
		},
		data() {
			return {
				formLabelWidth: '100px',
				disabledBtnState: false,
				isOperate: '',
				dengmiDataDetail: {
					title: '',
					grade: [],
					gradeDataList: [],
					gradeOptions: [],
					examListBox: false,
					examListBoes: false,
					hidenHeight: false,
					loading: true,
					exam: '',
					examList: [],
					examEditList: [],
					examDataList: [],
					examOptions: [],
					appendPList: [],
					examNameList: [],
					examSeleteList: []
				},
				// 搜索指标
				targetName: '',
				dialogTargetObj: {
					title: '选择指标',
					dialogVisible: false,
					dataId: '',
					width: '600px',
					defaultProps: {
						children: 'behaviorList',
						label: 'ruleName'
					},
					targetSeleteList: [],
					targetDataList: [],
					// 默认展开
					expandedKeys: [],
					// 默认选中的数据
					checkedkeys: []
				}
			}
		},
		computed: {
			...mapState({
				permissions: (state) => state.permissions
			})
		},
		created() {
			
		},
		mounted() {
			this.init();
			console.log('id================ ',this.dialogDetailObj.id)
		},
		watch: {
			'dialogDetailObj.keyId'(newVal, oldVal) {
				console.log(`新值：${newVal}`);
				console.log(`旧值：${oldVal}`);
				console.log('id================ ',this.dialogDetailObj.id)
				this.init();
			}
		},
		methods: {
			/**
			 * @Description: 加载
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:02:07
			 */
			init() {
				this.handleDetailData();
			},
			/**
			 * @Description:方案详情接口
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-25 14:50:21
			 */
			handleDetailData() {
				let id = this.dialogDetailObj.id;
				let gradeList = [];
				let addScoreList = [];
				this.dengmiDataDetail.examNameList = [];
				this.dengmiDataDetail.grade = [];
				this.dengmiDataDetail.appendPList = [];
				this._fet('/school/schoolCerProgramme/info/' + id, {
					
				}).then((res) => {
					if (res.data.code === '200') {
						let reg = res.data.data;
						let examList = res.data.data.examList;
						this.dengmiDataDetail.title = reg.name;
						
						// 考试方案返现
						if(reg.gradeInfo) {
							gradeList = JSON.parse(reg.gradeInfo);
							for(let k = 0; k < gradeList.length; k++) {
								this.dengmiDataDetail.grade.push(gradeList[k]);
							}
							//console.log(this.dengmiDataDetail.grade)
							this.handleGradeList();
							// this.handleExamList(this.dengmiDataDetail.grade);
						}
						
						// 获取全部考试方案
						this.dengmiDataDetail.examEditList = examList;
						if(examList && examList.length > 0) {
							for(let j = 0; j < examList.length; j++) {
								// 方案名称
								this.dengmiDataDetail.examNameList.push(examList[j].examName);	
								// 有附加分的方案列表
								if(examList[j].isAddScore == 1) {
									addScoreList.push(examList[j]);
								}
							}
						}
						if(this.dengmiDataDetail.examNameList.length > 0) {
							this.dengmiDataDetail.exam = this.dengmiDataDetail.examNameList.join(',');
						}
						
						if(addScoreList.length > 0) {
							for(let k = 0; k < addScoreList.length; k++) {
								this.dengmiDataDetail.appendPList.push({
									id: addScoreList[k].id,
									examName: addScoreList[k].examName,
									examId: addScoreList[k].examId,
									isAddScore: addScoreList[k].isAddScore,
									startTime: addScoreList[k].startTime,
									endTime: addScoreList[k].endTime,
									ruleIds: addScoreList[k].ruleIds,
									detaTime: [addScoreList[k].startTime, addScoreList[k].endTime],
									tableData: addScoreList[k].ruleList
								})
							}
							
						}
						setTimeout(()=>{
							this.dengmiDataDetail.loading = false;
						},900)
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description:获取年级信息列表
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:11:07
			 */
			handleGradeList() {
				let gradeData = this.dengmiDataDetail.grade;
				this.dengmiDataDetail.gradeDataList = [];
				this._fet('/school/schoolOrgan/listByCondition', {
					schoolId: this.$store.state.schoolId,
					organType: '3'
				}).then((res) => {
					if (res.data.code === '200') {
						let reg = res.data.data.list;
						
						for(let k = 0; k < reg.length; k++) {
							for(let j = 0; j < gradeData.length; j++) {
								if(reg[k].id === gradeData[j]) {
									this.dengmiDataDetail.gradeDataList.push(reg[k]);
								}
							}
						}
						
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description:获取未关联考试列表
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:11:08
			 */
			handleExamList(gradeIdList) {
				this._fet('/school/schoolCerProgramme/getNoJoinExamList', {
					gradeIdList: gradeIdList
				}).then((res) => {
					if (res.data.code === '200') {
						this.dengmiDataDetail.examOptions = res.data.data;
						if(this.dengmiDataDetail.examOptions.length <= 0) {
							this.dengmiDataDetail.examListBox = false;
						}
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description:学校指标行为列表接口
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:11:08
			 */
			handleTargetList() {
				let dataList = this.dengmiDataDetail.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				this.dialogTargetObj.expandedKeys = [];
				this.dialogTargetObj.checkedkeys = [];
				this._fet('/school/schoolMoralRule/listByCondition', {
					schoolId: this.$store.state.schoolId,
					ruleName: ''
				}).then((res) => {
					if (res.data.code === '200') {
						this.dialogTargetObj.targetDataList = res.data.data;
						for(let k = 0; k < dataList.length; k++) {
							if(dataId === dataList[k].id) {
								for(let j = 0; j < dataList[k].tableData.length; j++) {
									// 默认展开
									if(dataList[k].tableData[j].parentId) {
										this.dialogTargetObj.expandedKeys.push(dataList[k].tableData[j].parentId)
									}
									// 默认选中的数据
									this.dialogTargetObj.checkedkeys.push(dataList[k].tableData[j].id);
								}
								this.dialogTargetObj.targetSeleteList = dataList[k].tableData;
							}
						}
						
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description: 获取年级主键
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:22:10
			 */
			getEditGradeInfo(val) {
				if(val.length > 0) {
					this.handleExamList(val);
				} else {
					this.dengmiDataDetail.examOptions = [];
				}
			},
			/**
			 * @Description: 关联考试选择显示
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:22:13
			 */
			examEditFocus() {
				if(this.dengmiDataDetail.grade <= 0) {
					this.$message({
						type: 'warning',
						message: '请选择年级！'
					});
					return false;
				}
				if(this.dengmiDataDetail.examOptions.length > 0) {
					this.dengmiDataDetail.examListBox = true;
					this.dengmiDataDetail.examListBoes = false;
					this.dengmiDataDetail.hidenHeight = true;
				} else {
					this.$message({
						type: 'warning',
						message: '所选年级暂无关联考试信息！'
					});
				}
			},
			/**
			 * @Description: 获取关联考试选择
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:22:03
			 */
			getEditExamList(val){
				console.log(val)
				let dataList = this.dengmiDataDetail.examOptions;
				this.dengmiDataDetail.examSeleteList = [];
				for(let j = 0; j < dataList.length; j++) {
					for(let k = 0; k < val.length; k++) {
						if(dataList[j].id === val[k]) {
							this.dengmiDataDetail.examSeleteList.push(dataList[j]);
						}
					}
				}
			},
			/**
			 * @Description: 关联考试选择确定
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:22:52
			 */
			examSeleConfirm() {
				let dataSeleList = this.dengmiDataDetail.examList;
				let dataList = this.dengmiDataDetail.examOptions;
				let examNameList = [];
				let examSeleList = [];
				var examNameList2 = [];
				//console.log('dataSeleList ', dataSeleList)
				//console.log('dataList ', dataList)
				if(dataSeleList.length <= 0) {
					this.$message({
						type: 'warning',
						message: '请选择关联考试！'
					});
					return false;
				}
				for(let j = 0; j < dataList.length; j++) {
					for(let k = 0; k < dataSeleList.length; k++) {
						if(dataList[j].id === dataSeleList[k]) {
							examSeleList.push(dataList[j])
							examNameList.push(dataList[j].examName);
							examNameList2 = this.dengmiDataDetail.examNameList.concat(examNameList);
							if(examNameList2.length > 0) {
								this.dengmiDataDetail.exam = examNameList2.join(',');
							}
							examSeleList.forEach(item => {
								item.id = item.id;
								item.examName = item.examName;
								item.radio = '';
							});
						}
					}
				}
				this.dengmiDataDetail.examDataList = examSeleList;
				this.dengmiDataDetail.examListBox = false;
				this.dengmiDataDetail.examListBoes = true;
				
				// console.log('关联考试选择确定 ', this.dengmiDataDetail.examDataList)
			},
			/**
			 * @Description: 关联考试选择取消
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:22:52
			 */
			examSeleCancel() {
				this.dengmiDataDetail.examListBox = false;
				this.dengmiDataDetail.hidenHeight = false;
				this.dengmiDataDetail.examList = [];
				this.dengmiDataDetail.examDataList = [];
			},
			/**
			 * @Description: 关联考试显示获取附加分及删除操作
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:20
			 */
			isEditOperateShow(dataItem) {
				this.isOperate = dataItem.id;
			},
			/**
			 * @Description: 关联考试隐藏获取附加分及删除操作
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:28
			 */
			isEditOperateHide(dataItem) {
				this.isOperate = '';
			},
			/**
			 * @Description: 关联考试信息删除
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:38
			 */
			deleteEditExam(dataItem) {
				let index = this.dengmiDataDetail.examDataList.indexOf(dataItem);
				let dataList = this.dengmiDataDetail.examList;
				if (index !== -1) {
				  this.dengmiDataDetail.examDataList.splice(index, 1)
				}
				for(let j = 0; j < dataList.length; j++) {
					if(dataItem.id === dataList[j]) {
						this.dengmiDataDetail.examList.splice(j, 1)
					}
				}
				//console.log(this.dengmiDataDetail.examDataList)
				if(this.dengmiDataDetail.examDataList.length <= 0) {
					//console.log('12121 ',this.dengmiDataDetail.examDataList)
					// 关联考试为空时 隐藏选择弹窗
					this.dengmiDataDetail.examListBox = false;
					this.dengmiDataDetail.examListBoes = true;
				}
			},
			/**
			 * @Description: 获取关联考试信息附加分
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:47
			 */
			getEditExamRadio(dataItem) {
				console.log('编辑时获取关联考试信息附加分 ',dataItem)
				this.dengmiDataDetail.appendPList.push({
					id: dataItem.id,
					examName: dataItem.examName,
					examId: dataItem.id,
					isAddScore: dataItem.radio === '' ? 0 : 1,
					startTime: '',
					endTime: '',
					ruleIds: '',
					detaTime: [],
					tableData: []
				})
			},
			/**
			 * @Description: 指标选择弹窗
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 17:50:55
			 */
			targetShowEditClick(dataItem) {
				this.dialogTargetObj.dataId = dataItem.id;
				this.handleTargetList();
				this.dialogTargetObj.dialogVisible = true;
			},
			/**
			 * @Description: 指标选择
			 * @DoWhat: 
			 * @UseScenarios: 
			 * @Attention: 
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 16:18:05
			 */
			handleCheckChange(data, checked, indeterminate) {
				// 获取选中的数据
				let dataList = [];
				let dataLength = 0;
				dataList = this.$refs.tree.getCheckedNodes(true);
				dataLength = dataList.length;
				this.dialogTargetObj.targetSeleteList = [];
				for(let j = 0; j < dataLength; j++) {
					this.dialogTargetObj.targetSeleteList.push(dataList[j]);
				}
				console.log('指标获取选中的数据=== ', this.dialogTargetObj.targetSeleteList)
			},
			/**
			 * @Description: 查询指标
			 * @DoWhat: 
			 * @UseScenarios: 
			 * @Attention: 
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 16:18:16
			 */
			filterNode(value, data) {
				if (!value) {
					return true;
				}
				return data.ruleName.indexOf(value) !== -1;
			},
			/**
			 * @Description: 指标选择
			 * @DoWhat: 
			 * @UseScenarios: 
			 * @Attention: 
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 16:18:18
			 */
			handleNodeClick(data, node, nodeData) {
				let num = 0;
				// this.dialogTargetObj.targetSeleteList.forEach(item => {
				// 	item == data.label ? num++ : num;
				// })
				// if(num == 0) {
				// 	this.dialogTargetObj.targetSeleteList.push(data.label)
				// }
				// console.log('指标获取选中的数据 ==495', this.dialogTargetObj.targetSeleteList)
			},
			/**
			 * @Description: 指标选择删除
			 * @DoWhat: 
			 * @UseScenarios: 
			 * @Attention: 
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 17:25:07
			 */
			targetEditDelete(dataItem) {
				let dataList = this.$refs.tree.getCheckedNodes(true);
				let index = this.dialogTargetObj.targetSeleteList.indexOf(dataItem)
				if (index !== -1) {
				  this.dialogTargetObj.targetSeleteList.splice(index, 1)
				}
				// 删除左侧tree选中的节点
				this.$refs.tree.setChecked(dataItem.id, false, true);
			},
			/**
			 * @Description: 指标选择弹窗关闭
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 18:18:35
			 */
			handleTargetClose() {
				let dataList = this.dengmiDataDetail.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				for(let j = 0; j < dataList.length; j++) {
					if(dataId === dataList[j].id) {
						if(dataList[j].tableData.length <= 0) {
							this.dialogTargetObj.targetSeleteList = [];
							this.$refs.tree.setCheckedKeys([]);
						} 
					}
				}
				this.dialogTargetObj.dialogVisible = false;
			},
			/**
			 * @Description: 指标选择弹窗取消
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 18:18:35
			 */
			targetSeleEditCancel() {
				let dataList = this.dengmiDataDetail.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				for(let j = 0; j < dataList.length; j++) {
					if(dataId === dataList[j].id) {
						if(dataList[j].tableData.length <= 0) {
							this.dialogTargetObj.targetSeleteList = [];
							this.$refs.tree.setCheckedKeys([]);
							
						} 
					}
				}
				this.dialogTargetObj.dialogVisible = false;
			},
			/**
			 * @Description: 指标选择弹窗保存
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 18:18:35
			 */
			targetSeleEditConfirm() {
				let dataList = this.dengmiDataDetail.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				for(let j = 0; j < dataList.length; j++) {
					if(dataId === dataList[j].id) {
						dataList[j].tableData = this.dialogTargetObj.targetSeleteList;
					}
				}
				this.dialogTargetObj.dialogVisible = false;
			},
			/**
			 * @Description: 新增方案取消
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 17:11:51
			 */
			examEditCancel() {
				this.dengmiDataDetail.examListBox = false;
				this.dengmiDataDetail.examListBoes = false;
				this.dengmiDataDetail.hidenHeight = false;
				this.dengmiDataDetail.title = '';
				this.dengmiDataDetail.grade = [];
				this.dengmiDataDetail.exam = '';
				this.dengmiDataDetail.appendPList = [];
				this.dengmiDataDetail.examEditList = [];
				this.dengmiDataDetail.examSeleteList = [];
				this.dengmiDataDetail.examList = [];
				this.dengmiDataDetail.examDataList = [];
				// this.$refs.dengmiDataDetail.resetFields();
				this.$emit('change', 'NO')
			},
			/**
			 * @Description: 新增方案确定
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 17:11:59
			 */
			examEditConfirm() {
				let obj = {};
				let examList = [];
				let ruleIdsList = [];
				let ruleIds = '';
				let dataList = this.dengmiDataDetail.appendPList;
				let datedList = this.dengmiDataDetail.examEditList;
				let datesList = this.dengmiDataDetail.examSeleteList;
				console.log('全部考试方案 ',this.dengmiDataDetail.examEditList)
				console.log(this.dengmiDataDetail.appendPList)
				
				dataList.map((item, index)=>{
					for(let j = 0; j < item.tableData.length; j++) {
						ruleIdsList.push(item.tableData[j].id)
					}
					if(ruleIdsList.length > 0) {
						ruleIds = ruleIdsList.join(',');
					}
					examList.push({
						id: item.id,
						examName: item.examName,
						examId: item.examId,
						isAddScore: item.isAddScore,
						startTime: item.detaTime[0],
						endTime: item.detaTime[1],
						ruleIds: ruleIds
					})
				})
				
				// 编辑新增的数据
				datesList.map((item, index)=>{
					examList.push({
						examName: item.examName,
						examId: item.id,
						isAddScore: 0,
						startTime: '',
						endTime: '',
						ruleIds: ''
					})
				})
				
				datedList.map((item, index)=>{
					examList.push({
						id: item.id,
						examName: item.examName,
						examId: item.examId,
						isAddScore: 0,
						startTime: '',
						endTime: '',
						ruleIds: ''
					})
				})
				
				// 重复数据去重
				examList = examList.reduce(function(item, next) {
					   obj[next.examId] ? '' : obj[next.examId] = true && item.push(next);
					   return item;
					}, []);
				
				// this.$refs[formName].validate((valid) => {
				// 	if(valid) {
						
				// 	}
				// });
				
				this.disabledBtnState = true;
				this._fet('/school/schoolCerProgramme/save', {
					name: this.dengmiDataDetail.title,
					gradeInfo: this.dengmiDataDetail.grade,
					examList: examList
				}).then((res) => {
					if (res.data.code === '200') {
						this.dengmiDataDetail.examListBox = false;
						this.dengmiDataDetail.examListBoes = false;
						this.dengmiDataDetail.hidenHeight = false;
						this.dengmiDataDetail.title = '';
						this.dengmiDataDetail.grade = [];
						this.dengmiDataDetail.exam = '';
						this.dengmiDataDetail.appendPList = [];
						this.dengmiDataDetail.examEditList = [];
						this.dengmiDataDetail.examSeleteList = [];
						this.dengmiDataDetail.examList = [];
						this.dengmiDataDetail.examDataList = [];
						// this.$refs.dengmiDataDetail.resetFields();
						this.$message.success(res.data.msg);
						this.$emit('change', 'YES');
						this.disabledBtnState = false;
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
						this.disabledBtnState = false;
					}
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.programme-detail-pages {}
	
	.programme-detail-pages .el-date-editor {
		width: 100%;
	}
	
	.programme-detail-pages .exam-wrap {
		position: relative;
	}
	
	/* 关联考试列表弹窗 */
	.programme-detail-pages .exam-wrap .exam-wrap-list {
		position: absolute;
		top: 52px;
		left: 0;
		width: 100%;
		border-radius: 4px;
		border: 1px solid #dcdee0;
	}
	
	
	.programme-detail-pages .exam-wrap-list-btn {
		display: flex;
		padding-left: 6px;
		border-bottom: 1px solid #dcdfe6;
	}
	
	.programme-detail-pages .exam-wrap-list-btn .confirm-btn {
		width: 22px;
		height: 32px;
		padding-top: 6px;
		cursor: pointer;
		box-sizing: border-box;
	}
	
	.programme-detail-pages .exam-wrap-list-btn .confirm-btn img {
		width: 100%;
	}
	
	.programme-detail-pages .exam-wrap-list-btn .cancel-btn {
		width: 20px;
		height: 32px;
		margin-left: 14px;
		padding-top: 6px;
		cursor: pointer;
		box-sizing: border-box;
	}
	
	.programme-detail-pages .exam-wrap-list-btn .cancel-btn img {
		width: 100%;
	}
	
	.programme-detail-pages .exam-wrap-list-box {
		height: 110px;
		overflow-y: auto;
		padding-top: 10px;
		padding-left: 11px;
		box-sizing: border-box;
	}
	
	.programme-detail-pages .exam-wrap-list-box .el-checkbox {
		display: block;
	}
	
	.programme-detail-pages .exam-wraper-list {
		position: absolute;
		top: 52px;
		left: 0;
		width: 100%;
		height: 108px;
		overflow-y: auto;
		border-radius: 4px;
		padding-top: 10px;
		padding-left: 11px;
		padding-right: 9px;
		box-sizing: border-box;
		border: 1px solid #dcdee0;
	}
	
	.programme-detail-pages .exam-wraper-list .exams-title {
		display: flex;
		margin-right: 40px;
	}
	
	.programme-detail-pages .exam-wraper-list .exams-title .el-icon-close {
		cursor: pointer;
		margin-top: 6px;
	}
	
	.programme-detail-pages .exam-wraper-list .exams-title span{
		width: 158px;
		overflow: hidden; 
		white-space: nowrap; 
		text-overflow: ellipsis; 
	}
	
	.programme-detail-pages .exam-wraper-list .exam-wraper-list-cell {
		display: flex;
		padding-left: 12px;
	}
	
	.programme-detail-pages .exam-wraper-list .exam-wraper-list-cell:hover {
		border-radius: 4px;
		background-color: #f4f4f4;
	}
	
	/* 选择指标 */
	.programme-detail-pages .target-list {
		padding: 0 20px;
		box-sizing: border-box;
	}
	
	.programme-detail-pages .target-list-query {
		width: 50%;
	}
	
	.programme-detail-pages .target-list-number {
		width: 50%;
		padding-left: 20px;
		box-sizing: border-box;
	}
	
	.programme-detail-pages .target-list-boes {
		display: flex;
		margin-top: 10px;
	}
	
	.programme-detail-pages .target-list-boes-row {
		width: 50%;
		height: 370px;
		overflow-y: auto;
		border: 1px solid #e2e2e2;
	}
	
	/* 已选择的指标 */
	.programme-detail-pages .target-list-boes-rt {
		display: flex;
		flex-wrap: wrap;
		align-content:flex-start;
	}
	
	.programme-detail-pages .target-list-boes-rt .target-list-boes-rt-row {
		height: 30px;
		line-height: 30px;
		padding: 0 10px; 
		box-sizing: border-box; 
		background-color: #f2f2f2;
		margin-left: 10px;
		margin-top: 10px;
	}
	
	.programme-detail-pages .target-selete-cell {
		position: relative;
	}
	
	.programme-detail-pages .target-selete-cell .target-selete-cell-delete {
		position: absolute;
		top: -12px;
		right: -14px;
	}
	
	.programme-detail-pages .target-selete-cell .target-selete-cell-delete /deep/ .el-icon-error {
		color: #ff0000;
		cursor: pointer;
	}
	
	/deep/ .is-disabled .el-input__inner {
		color: #666;
		border-color: #edf0f2;
		background-color: #f8f8f8;
	}
	
	/deep/ .is-disabled.el-date-editor {
		color: #666;
		border-color: #edf0f2;
		background-color: #f8f8f8;
	}
	
	/deep/ .el-range-editor.is-disabled input{
		color: #666;
		border-color: #edf0f2;
		background-color: #f8f8f8;
	}
	
	/deep/ .el-range-editor.is-disabled .el-range-separator {
		color: #666;
		border-color: #edf0f2;
		background-color: #f8f8f8;
	}
</style>